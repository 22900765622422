import React from "react";
import Parser from "../components/parser.js";
import Layout from "../components/layout/layout";
import decodeHTMLEntities from "../components/utils/decodeHTMLentities.js";
import { Link, graphql } from "gatsby";

const Sitemap = ({data}) => {
  const template = data.sitemap;
  const extractTitles = (html) => {
    let titles = [...html.matchAll(/<h([1-6])( (.*?))?>(.*?)<\/h[1-6]>/gi)];
    titles = titles.map(e => {
      const title = {
        value: e[4],
        level: parseInt(e[1])
      };
      if (typeof e[3] !== "undefined") {
        const id = [...e[3].matchAll(/id=\\?["'](.*?)\\?["']/gi)];
        if (id.length) {
          title.id = id[0][1];
        }
      }
      return title;
    });
    return titles;
  };
  const Entry = (props) => {
    if (props.excludeLocalSearch) return <></>;
    const Tag = parseInt(props.level) <= 6 ? "h"+props.level : "p";
    if (!props.uri) {
      return <li><Tag>{ <Parser content={ decodeHTMLEntities(props.title) } /> }</Tag>
        { (props.description && props.description.length)
          ? <Parser content={ "<p class='description'>"+decodeHTMLEntities(props.description)+"</p>" } />
          : <></>
        }
        { props.children }</li>;
    }
    return <li><Tag>
      <Link
        to={ props.uri }
        title={ decodeHTMLEntities(props.linkTitle)  }
      >
        { decodeHTMLEntities(props.title) }
      </Link>
      { (props.description && props.description.length)
        ? <Parser content={ "<p class='description'>"+decodeHTMLEntities(props.description)+"</p>" } />
        : <></>
      }
    </Tag>{ props.children }</li>;
  };
  const walkPages = (pages, parents, level) => {
    return pages.sort((a, b) => {
      return a.title.localeCompare(b.title);
    })
      .map((page) => {
        const titles = extractTitles(page.content);
        const children = data.allWpPage.nodes
          .filter(child => child.wpParent && child.wpParent.node.slug === page.slug);
        const uri = "/" + [...parents, page.slug].join("/") + "/";
        return <>
          <Entry
            uri={ uri }
            title={ page.seo?.openGraphTitle || page.title }
            description={page.seo?.description}
            excludeLocalSearch={page.seo?.excludeLocalSearch}
            level={level}
            linkTitle={ "Visiter la page "+page.title }
          ><ul className="is-titles">
              {
                titles.map(e => {
                  return <Entry
                    uri={ e.id ? (uri+"#"+e.id) : false }
                    title={ e.value }
                    linkTitle={ "Consulter la section "+e.value+" de la page "+page.title }
                    level={ level + e.level} 
                  />;
                })
              }
            </ul>
            <ul>
              {
                (children.length)
                  ? walkPages(children, [...parents, page.slug], level+1)
                  : <></>
              }
            </ul>
          </Entry>
        </>;
      });
  };
  const homeTitles = extractTitles(data.home.content);
  const searchTitles = extractTitles(data.search.content);
  const Content = () => (<section id="sitemap"><ul className="is-style-list-no-disc">
    <Entry
      uri="/"
      title={ data.home.seo?.openGraphTitle || data.home.title }
      description={data.home.seo?.description}
      excludeLocalSearch={data.home.seo?.excludeLocalSearch}
      level={2}
      linkTitle="Retourner à la page d'accueil"
    >
      <ul className="is-titles">
        {
          homeTitles.map(e => {
            return <Entry
              uri={ e.id ? ("/#"+e.id) : false }
              title={ e.value }
              linkTitle={ "Consulter la section \""+e.value+"\" de la page d'accueil" }
              level={ 2 + e.level} 
            />;
          })
        }
      </ul>
      <ul>
        {
          walkPages(data.allWpPage.nodes.filter((page) => page.wpParent === null), [], 2)
        }
      </ul>
    </Entry>
    <Entry
      uri={"/"+data.news.slug+"/"}
      title={ data.news.seo?.openGraphTitle || data.news.title }
      description={ data.news.seo?.description }
      level={2}
      linkTitle="Découvrir les dernières actualités"
    >
      <ul>
        {
          data.allWpCategory.nodes
            .sort((a,b) => {
              return a.name.localeCompare(b.name);
            })
            .map(cat => {
              return <>
                <Entry
                  uri={"/"+data.news.slug+"/"+cat.slug+"/"}
                  title={ cat.seo?.openGraphTitle || cat.name }
                  description={cat.seo?.description || cat.description}
                  excludeLocalSearch={cat.seo?.excludeLocalSearch}
                  level={3}
                  linkTitle={ "Découvrir les dernières actualités de la catégorie "+cat.name }
                >
                  <ul>
                    {
                      data.allWpPost.edges
                        .filter(e => e.node.categories.nodes.some(postCategory => postCategory.slug === cat.slug))
                        .map(e => {
                          const titles = extractTitles(e.node.content);
                          return <>
                            <Entry
                              uri={"/"+data.news.slug+"/"+cat.slug+"/"+e.node.slug+"/"}
                              title={ e.node.seo?.openGraphTitle || e.node.title }
                              description={e.node.seo?.description}
                              excludeLocalSearch={e.node.seo?.excludeLocalSearch}
                              level={4}
                              linkTitle={ "Consulter l'actualité "+e.node.title }
                            ><ul className="is-titles">
                                {
                                  titles.map(title => {
                                    return <Entry
                                      uri={ title.id ? ("/"+data.news.slug+"/"+cat.slug+"/"+e.node.slug+"/#"+e.id) : false }
                                      title={ title.value }
                                      linkTitle={ "Consulter la section \""+title.value+"\" de l'actualité \""+ e.node.title +"\"" }
                                      level={ 4 + title.level} 
                                    />;
                                  })
                                }
                              </ul></Entry>
                          </>;
                        })
                    }
                  </ul>
                </Entry>
              </>;
            })
        }
      </ul>
    </Entry>
    <Entry
      uri={"/"+data.search.slug+"/"}
      title={ data.search.seo?.openGraphTitle || data.search.title }
      description={ data.search.seo?.description }
      level={2}
      linkTitle="Visiter cette page pour effectuer une recherche sur le site"
    ><ul className="is-titles">
        {
          searchTitles.map(e => {
            return <Entry
              uri={ e.id ? ("/"+data.search.slug+"/#"+e.id) : false }
              title={ e.value }
              linkTitle={ "Consulter la section \""+e.value+"\" de la page de recherche" }
              level={ 2 + e.level} 
            />;
          })
        }</ul></Entry>
  </ul></section>);
  return (<Layout infos={ { title: template.title } }  seo={ template.seo }>
    <Parser content={ template.content } child={ (Content) } />
  </Layout>);
};

export const query = graphql`
  query {
    wp {
      generalSettings {
        title
      }
    }
    allWpPost(filter: {
      seo: {
        redirectUrl: {
          eq: ""
        }
      }
    }
    sort: {fields: [date], order: DESC}
    ) {
      edges {
        node {
          title
          slug
          content
          categories {
            nodes {
              slug
              name
            }
          }
          seo {
            openGraphTitle
            description
            excludeLocalSearch
          }
        }
      }
    }
    allWpPage(filter: {isFrontPage: {eq: false}, isPostsPage: {eq: false}}) {
      nodes {
        title
        content
        slug
        wpParent {
          node {
            slug
            ... on WpPage {
              title
            }
          }
        }
        seo {
          openGraphTitle
          description
          excludeLocalSearch
        }
      }
    }
    news: wpPage(isPostsPage: {eq: true}) {
      slug
      title
      seo {
          openGraphTitle
          description
          excludeLocalSearch
        }

    }
    home: wpPage(isFrontPage: {eq: true}) {
      title
      content
      seo {
        openGraphTitle
        description
        excludeLocalSearch
      }
    }
    allWpCategory(filter: {slug: {ne: "uncategorized"}}) {
      nodes {
        slug
        name
        description
        seo {
          openGraphTitle
          description
          excludeLocalSearch
        }
      }
    }
    search: wpTemplate(template_reference: {referenceTemplate: {eq: "search"}}) {
      slug
      title
      content
      seo {
        openGraphTitle
        description
        excludeLocalSearch
      }
    }
    sitemap: wpTemplate(template_reference: {referenceTemplate: {eq: "sitemap"}}) {
      content
      title
      seo {
        title
        description
      }
    }
  }
`;
export default Sitemap;
